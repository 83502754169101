import { IconProps } from '@/utilities/types/Icon';

const GreatBritain = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#0052B4" />
      <path
        d="M14.357 3.143 9.5 8l4.857 4.857a8.043 8.043 0 0 1-1.5 1.5L8 9.5l-4.857 4.857a8.043 8.043 0 0 1-1.5-1.5L6.5 8 1.643 3.143a8.047 8.047 0 0 1 1.5-1.5L8 6.5l4.857-4.857a8.047 8.047 0 0 1 1.5 1.5Z"
        fill="#EEE"
      />
      <path
        d="M9 .062c.34.042.674.106 1 .19V6h5.748c.083.326.147.66.19 1L15 8l.938 1c-.043.34-.107.674-.19 1H10v5.748c-.326.084-.66.148-1 .19L8 15l-1 .938a7.964 7.964 0 0 1-1-.19V10H.252a7.964 7.964 0 0 1-.19-1L1 8 .062 7c.042-.34.106-.674.19-1H6V.252c.325-.084.66-.148 1-.19L8 1l1-.938Z"
        fill="#EEE"
      />
      <path
        d="M9 .062A8.077 8.077 0 0 0 8.016 0h-.032C7.651 0 7.322.022 7 .062V7H.062a8.079 8.079 0 0 0 0 2H7v6.938a8.07 8.07 0 0 0 2 0V9h6.938a8.07 8.07 0 0 0 0-2H9V.062Z"
        fill="#D80027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.135 1.865c.18.152.355.311.522.478L10 6V5l3.135-3.135Zm-11.27 1a8.05 8.05 0 0 1 .478-.522L6 6H5L1.865 2.865Zm11.792 10.792c.167-.167.326-.341.478-.522L11 10h-1l3.657 3.657Zm-10.792.478L6 11v-1l-3.657 3.657c.167.167.341.326.522.478Z"
        fill="#D80027"
      />
    </svg>
  );
};

export default GreatBritain;
